<template>
	<page>
		<module :title="id ? '编辑合同' : '新建合同'">
			<c-form ref="form">
				<c-form>
					<c-form-item type="hidden" name="ht_id"></c-form-item>
					<c-form-item type="input" label="合同标题" name="name" required></c-form-item>
					<c-form-item v-model="currentProjectId" type="select" label="合同所属项目" name="project_id" :options="projectList" :search="!mobile" required @search="projectSeatchHandle"></c-form-item>
					<c-form-item type="input" label="合同编号" placeholder="自动生成" disabled></c-form-item>
					<c-form-item v-model="fileType" type="radio" label="合同文件" name="yz_qs_type" required :options="fileTypeOption"></c-form-item>
					<c-form-item v-show="fileType" :label="mobile ? '上传合同' : ''" :required="!!mobile">
						<c-upload-file v-model="filePath" :button="`上传合同`" type="pdf" size="3072"></c-upload-file>
					</c-form-item>
				</c-form>
			</c-form>
			
			<c-form ref="jiaForm" title="合同签约方">
				<c-form-item type="input" label="甲方单位" name="yzdw_name" disabled></c-form-item>
				<c-form-item label="乙方单位">
					<div class="sub-form">
						<c-form ref="yiForm">
							<c-form-item type="input" label="中标企业" name="zbqy_name" disabled></c-form-item>
							<c-form-item type="input" label="企业法人" name="zbqy_user_realname" disabled></c-form-item>
							<c-form-item type="input" label="经办人" name="jbr_name"></c-form-item>
							<c-form-item type="input" label="经办人电话" name="jbr_phone"></c-form-item>
						</c-form>
					</div>
				</c-form-item>
			</c-form>
			
			<div slot="button" class="flex-center">
				<c-button size="m" @click="submit">确认提交</c-button>
			</div>
		</module>
	</page>
</template>

<script>
	export default {
		name: 'contract_edit',
		
		props: {
			id: String,
			projectId: String
		},
		
		data() {
			return {
				mobile: this.$store.state.mobile,
				projectList: [],
				currentProjectId: '',
				fileType: '',
				fileTypeOption: [{
					name: '甲方未盖章',
					value: 2
				}, {
					name: '甲方已盖章',
					value: 1
				}],
				filePath: ''
			}
		},
		
		watch: {
			id() {
				this.getDetail();
			},
			
			projectId() {
				this.$refs.form.set({
					project_id: this.projectId
				});
				this.fileType = '';
				this.filePath = '';
			},
			
			currentProjectId(val) {
				if (val) {
					this.getProject();
				}
			}
		},
		
		mounted() {
			this.request({
				url: '/project/select_list',
				data: {
					type: 1
				},
				success: data => {
					this.projectList = Array.from(data, item => {
						return {
							name: `【${item.code}】${item.name}`,
							value: item.id
						}
					});
						
					if (this.projectId) {
						this.$refs.form.set({
							project_id: this.projectId
						});
					}
				}
			});
			
			if (this.id) {
				this.getDetail();
			}
		},
		
		methods: {
			getDetail() {
				this.request({
					url: '/project/ht/detail',
					data: {
						ht_id: this.id
					},
					success: data => {
						this.$refs.form.set(data.htInfo);
						this.$refs.form.set({
							ht_id: data.htInfo.id
						});
						this.fileType = 1;
						this.filePath = data.htInfo.file_path;
					}
				});
			},
			
			getProject() {
				this.request({
					url: '/project/detail',
					data: {
						project_id: this.currentProjectId
					},
					success: data => {
						this.$refs.jiaForm.set(data.project_info);
						this.$refs.yiForm.set(data.project_info);
						this.$refs.yiForm.set({
							jbr_name: data.project_info.zbqy_user_realname,
							jbr_phone: data.project_info.zbqy_user_phone
						});
					}
				});
			},
			
			projectSeatchHandle(keyword, callback) {
				this.request({
					url: '/project/select_list',
					data: {
						type: 1,
						keyword
					},
					success: data => {
						callback(Array.from(data, item => {
							return {
								name: `【${item.code}】${item.name}`,
								value: item.id
							}
						}));
					}
				});
			},
			
			submit() {
				var company = this.$refs.yiForm.serialize();
				this.$refs.form.submit({
					url: this.id ? '/project/ht/update' : '/project/ht/add',
					rule: {
						name: '请填写合同标题',
						project_id: '请选择合同所属项目',
						file_path: [{
							type: 'function',
							word: '请上传合同文件',
							fun: () => {
								return !!this.filePath;
							}
						}]
					},
					dataFormatter: data => {
						data.file_path = this.filePath;
						data.jbr_name = company.jbr_name;
						data.jbr_phone = company.jbr_phone;
						return data;
					},
					success: (data, res) => {
						this.$message({
							text: '提交成功',
							resolve: () => {
								if (!this.mobile) {
									this.redirectTo('/contract_list');
								}
							}
						});
						
						if (this.mobile) {
							this.navigateTo('/contract_list');
						}
					}
				});
			}
		}
	};
</script>

<style lang="stylus" scoped>
	@import '~@/assets/style/mobile'
	
	.sub-form {
		box-sizing: border-box;
		width: 654px;
		padding: 20px 120px 20px 30px;
		background: #F8F9FE;
		
		mobile({
			width: auto,
			padding: .4rem .05rem
		})
	}
</style>